<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
      <el-button type="primary" @click="SaveAndComeBack('form')">
        Сохранить и вернуться
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Логотип" prop="logo">
        <Uploader
          withoutDeleteRequest
          :limit="1"
          :files="form.logo && form.logo.original ? [form.logo] : []"
          @upload="uploadLogo"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Внешний URL" prop="externalLink">
        <el-input v-model="form.externalLink"></el-input>
      </el-form-item>
      <el-form-item label="Категория" prop="categoryId">
        <el-select
          v-model="form.categoryId"
          placeholder="Выберите"
          :class="$style.categorySelect"
          v-el-select-loadmore="loadMoreCategories"
        >
          <el-option
            v-for="item in categories"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number
          v-model="form.orderField"
          :min="0"
          :max="999999999"
        ></el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Uploader from '@/components/moleculs/AddwineUploader.vue'

export default {
  components: {
    Uploader,
  },
  async created() {
    await this.getTeamMember()
    await this.getCategoriesList()
  },
  data() {
    return {
      categories: [],
      categoriesOptions: {
        limit: 50,
        page: 1,
        total: 0,
      },
      form: {
        logo: {
          caption: '',
          isActive: true,
          order_field: 0,
          original: '',
        },
        name: '',
        externalLink: '',
        orderField: 0,
        categoryId: '',
        logoId: '',
      },
      rules: {
        logo: [
          {
            type: 'object',
            required: true,
            trigger: 'change',
            validator: this.validateImage,
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            trigger: 'blur',
            message: 'В строке содержится слово длиной больше 30 символов',
            validator(rule, value, callback) {
              if (value.split(' ').some((item) => item.length > 30))
                return callback(false)
              return callback()
            },
          },
        ],
        categoryId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    async getTeamMember() {
      const loading = this.$loading({
        lock: true,
      })

      const { value } =
        await delivery.ContentServiceCore.ClientsActions.getById(
          this.$route.params.id,
        )

      loading.close()

      this.form = { ...this.form, ...value }
    },
    uploadLogo(images) {
      this.form.logo = images[0]
      this.form.logo.id = this.form.logoId
    },
    validateImage(_, value, callback) {
      value && value.original
        ? callback()
        : callback(new Error('Пожалуйста, загрузите картинку'))
    },
    async getCategoriesList() {
      const { value, error } =
        await delivery.ContentServiceCore.ClientCategoriesActions.getList({
          limit: this.categoriesOptions.limit,
          page: this.categoriesOptions.page,
          orderDir: 'asc',
          orderBy: 'name',
        })
      if (error) return
      this.categories = [...this.categories, ...value.data]
      this.categoriesOptions.total = value.meta.count
    },
    loadMoreCategories() {
      const { page, limit, total } = this.categoriesOptions
      if (limit * page < total) {
        this.categoriesOptions.page++
        this.getCategoriesList()
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })
          const data = { ...this.form }
          const result =
            await delivery.ContentServiceCore.ClientsActions.update(
              this.$route.params.id,
              data,
            )
          if (!result.error) {
            this.$message({
              message: 'Клиент успешно обновлён',
              type: 'success',
            })
          }
          loading.close()
        }
      })
    },
    async SaveAndComeBack(form){
      await this.submitForm(form)
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
  }
  .form {
    & > div > label {
      text-align: left;
    }
    .categorySelect {
      width: 100%;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
